import React from "react";
// import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MapChart from "../components/MapChart";
// import { makeStyles } from "@material-ui/core/styles";

export const HomePage: React.FC = () => {
  return (
    <div>
      <h1>Home Page</h1>
      <MapChart />
      <Button variant="contained" color="primary">
        Hello! I am a moosh.
      </Button>
    </div>
  );
};
